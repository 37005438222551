import { Controller } from "@hotwired/stimulus"
import { observeMutations } from "../helpers/mutation_observer_helpers"

export default class extends Controller {
  static targets = ["item"]
  static values = { size: Number, name: String }

  initialize() {
    observeMutations.bind(this)(this.refreshSizeValue)
  }

  refreshSizeValue() {
    this.sizeValue = this.filteredItemTargets.length
  }

  // Private

  get filteredItemTargets() {
    if (this.hasNameValue) {
      return this.itemTargets.filter(item => item.dataset.listName === this.nameValue)
    } else {
      return this.itemTargets
    }
  }
}
