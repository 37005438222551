import { Controller } from "@hotwired/stimulus"

const loaded_event = new Event("DOMContentLoaded", { bubbles: true });
export default class extends Controller {
  static targets = ["entries", "pagination"]
  static values = {
    scrollSelector: { type: String, default: "#main" }
  }

  initialize() {
    this.loading = false // prevent multiple calls to loadMore when this.loading is undefined
  }

  connect() {
    this.scrollingElement = document.querySelector(this.scrollSelectorValue)
    if (this.scrollingElement == null) { console.log("No container for infinite scroll"); return }

    this.scrollingElement.addEventListener("scroll", this.scroll.bind(this))
  }

  scroll() {
    if (!this.hasPaginationTarget) { return }
    let next_page = this.paginationTarget.querySelector("a[rel='next']")
    if (next_page == null) { return }

    let url = next_page.href

    const bottomScrollPosition = this.scrollingElement.scrollTop + this.scrollingElement.clientHeight
    const triggerPoint = this.scrollingElement.scrollHeight - 400

    if (bottomScrollPosition >= triggerPoint) {
      this.loadMore(url)
    }
  }

  loadMore(url) {
    if (this.loading) { return }
    this.loading = true

    this.showSpinner();

    fetch(url, {
      headers: {
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      }
    }).then((response) => response.json())
      .then((data) => {
        this.entriesTarget.insertAdjacentHTML('beforeend', data.entries);
        this.paginationTarget.innerHTML = data.pagination;
        this.loading = false;
        this.hideSpinner();
        document.dispatchEvent(loaded_event);
      })
  }

  showSpinner() {
    this.entriesTarget.insertAdjacentHTML('afterend', `
      <div class="row justify-content-center">
        <div id="spinner" class="my-5 spinner-grow text-primary" role="status"><span class="sr-only">Loading...</span></div>
      </div>
    `)
  }

  hideSpinner() {
    document.getElementById('spinner').remove()
  }
}
