import { Controller } from "@hotwired/stimulus";
import debounce from "lodash/debounce"

export default class extends Controller {
  static values = {
    previewUrl: String,
    turboFrameId: String
  };

  connect() {
    this.turboFrame = document.getElementById(this.turboFrameIdValue);
    this.lastSubmittedFormData = null;
    this.update = debounce(this.update, 500, { leading: true });
    this.update()
  }

  update() {
    const formData = new FormData(this.element);

    // Don't submit the form if the data hasn't changed
    if (this.lastSubmittedFormData !== null && this.formDataIsEqual(formData, this.lastSubmittedFormData)) { return }

    this.fadeOutTurboframe();

    const form = this.element.cloneNode(true);
    const methodField = form.querySelector('input[name="_method"]');
    if (methodField) methodField.value = "post";

    const originalSelects = Array.from(this.element.querySelectorAll("select"));
    const clonedSelects = Array.from(form.querySelectorAll("select"));

    // Copy selected options from original select to cloned select, for some reason they don't get copied
    originalSelects.forEach((select, index) => {
      Array.from(clonedSelects[index].options).forEach((option, optIndex) => {
        option.selected = select.options[optIndex].selected;
      });
    });

    form.action = this.previewUrlValue;
    form.dataset.controller = null;
    form.dataset.turboAction = null;
    form.dataset.turboFrame = this.turboFrameIdValue;

    form.classList.add("hidden");
    document.body.appendChild(form);
    form.requestSubmit();
    form.remove();

    this.lastSubmittedFormData = formData;
  }

  fadeOutTurboframe() {
    console.log("fade out turboframe")
    const animatedItems = this.turboFrame.getElementsByClassName("animate__animated animate__fadeIn")

    for (var item of animatedItems) {
      item.classList.remove("animate__fadeIn")
      item.classList.add("animate__fadeOut", "animate__faster")
    }
  }

  formDataIsEqual(formData1, formData2) {
    return JSON.stringify([...formData1.entries()]) === JSON.stringify([...formData2.entries()])
  }
}
