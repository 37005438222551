import { Controller } from "@hotwired/stimulus"
import { observeMutations } from "../helpers/mutation_observer_helpers"
import { isElementVisible } from "../utils"

export default class extends Controller {
  static targets = ["keyword"]

  initialize() {
    observeMutations.bind(this)(this.update)
  }

  connect() {
    this.update()
  }

  get populatedKeywords() {
    // keywords that are visible, and contain a value input that is not blank
    return this.keywordTargets.filter(keyword => {
      let populated = isElementVisible(keyword) && keyword.querySelector("input[name*=value]").value !== ""

      return populated
    })
  }

  update() {
    if (this.populatedKeywords.length < 2) {
      // If there are less than 2 items, remove the visible class from all items
      this.keywordTargets.forEach(keyword => keyword.classList.remove("feed-keyword--show-impact"))
    } else {
      // toggle the visible class depending on if they are populated
      this.keywordTargets.forEach(keyword => {
        keyword.classList.toggle("feed-keyword--show-impact", this.populatedKeywords.includes(keyword))
      })
    }
  }
}
