import { Controller } from "@hotwired/stimulus"
import { useClickOutside } from "stimulus-use";

export default class extends Controller {
  static values = { closeOnBackdropClick: { type: Boolean, default: true } }

  connect() {
    // There is something weird going on here with some modals closing with the escape key
    // and some not. They seem to ignore the keyboard: false option, but also some only work due to the
    // handleKeyup function below.
    // We definitely want to prevent accidental closing of the new post modal.
    $(this.element).modal({ keyboard: false, show: true, backdrop: 'static' });
    useClickOutside(this, { element: this.element.getElementsByClassName("modal-content")[0] });

    setTimeout(() => {
      this.stackNestedModals()
    }, 200)

    // Close the modal when a link is clicked that is targetted to modal _top
    this.element.addEventListener('click', this.linkClicked.bind(this))
  }

  disconnect() {
    // The modal overlay doesn't fade out in time when navigating away from the page
    // This means that it is cached by turbo and re-appears when navigating back to the page
    // This is a hacky way to prevent that as it only occurs when the modal has a fade animation
    $(this.element).removeClass('fade')
    this.close()
  }

  linkClicked(e) {
    if ((e.target instanceof HTMLAnchorElement) && e.target.dataset.turboFrame == "_top") {
      this.disconnect()
    }
  }

  clickOutside(event) {
    // Click outside is targetted to the modal-content element while this.element is the modal container which covers the whole screen
    // This means that the modal will close when clicking outside of the modal-content but still inside the modal container
    // This prevents the modal closing when clicking on 'outside' elements like datepickers which are appended to the body
    if (this.closeOnBackdropClickValue && event.target === this.element) {
      this.close()
    }
  }

  close() {
    // Remove the modal element so it doesn't blanket the screen
    $(this.element).modal('hide');

    // Prevent turbo re-opening the modal on restoration

    // Find the closest ancestor that matches the selector "turbo-frame"
    const turboFrame = this.element.closest("turbo-frame");
    if (turboFrame !== null) { turboFrame.src = undefined; }

    this.element.remove();
  }

  handleKeyup(e) {
    if (e.code == "Escape") {
      this.close()
    }
  }

  handleSubmit(e) {
    if (e.detail.success && e.detail.formSubmission.submitter) {
      this.close()
    }
  }

  // Bootstrap does not support nested modals, so we need to handle stacking
  // Set z-indices and overlay the modal backdrop so that nested modals appear on top of each other
  stackNestedModals() {
    var numVisibleModals = $('.modal:visible').length
    if (numVisibleModals) {
      var lastZIndex = 0
      for (var i = 0; i < numVisibleModals; i++) {
        let el = $('.modal:visible')[i];
        let zIndex = 1000 + (10 * (i + 1));
        document.getElementById(el.id).style.zIndex = zIndex;
        lastZIndex = zIndex;
      }
      $('.modal-backdrop').not('.modal-stack').css('z-index', lastZIndex - 1).addClass('modal-stack');
    }
  }
}
