import { Controller } from "@hotwired/stimulus";
import "../vendor/query-builder-widget.js";
import flatpickr from 'flatpickr';

export default class extends Controller {
  static targets = ["form", "query", "queryBuilder"]
  static values = {
    config: Object
  }

  initialize() {
    this.onQueryBuilderChange = this.onQueryBuilderChange.bind(this)
  }

  connect() {
    // Bind the controller to the element so we can access it in the onQueryBuilderChange() handler
    // https://dev.to/leastbad/the-best-one-line-stimulus-power-move-2o90
    this.element[
      (str => {
        return str
          .split('--')
          .slice(-1)[0]
          .split(/[-_]/)
          .map(w => w.replace(/./, m => m.toUpperCase()))
          .join('')
          .replace(/^\w/, c => c.toLowerCase())
      })(this.identifier)
    ] = this
    if (!this.hasQueryBuilderTarget) { return }

    window.QueryBuilderWidget.mount({
      parentElement: "#query-builder",
      sendQuery: this.onQueryBuilderChange,
      config: this.configValue,
      datepicker: (options) => flatpickr('.flatpickr', options),
    });

    setTimeout(this.initTooltips, 1000);
  }

  disconnect() {
    window.QueryBuilderWidget.unmount();
  }

  // Use a delay to avoid a race condition whereby window.searchQuery is fetched before the widget has a chance to update it
  onQueryBuilderChange() {
    this.updateQueryFields()
    const form = this.formTarget;
    setTimeout(() => { form.requestSubmit(); }, 100);
  }

  updateQueryFields() {
    const { searchQuery } = window;
    if (searchQuery === undefined) { return }

    this.queryTarget.value = searchQuery;
  }

  initTooltips() {
    $("#query-builder .appendNewQueryButton, #query-builder .queryBlock_remove").tooltip({
      trigger: 'hover'
    }).on('click mousedown mouseup', () => {
      $('.tooltip').remove();
    });
  }
}
