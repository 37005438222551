import { Controller } from "@hotwired/stimulus";
import { Picker } from "emoji-mart";
import { useClickOutside } from "stimulus-use";

export default class extends Controller {
  static targets = ["field", "button", "pickerContainer"];

  connect() {
    useClickOutside(this);
    this.initializePicker();
  }

  clickOutside() {
    this.pickerContainerTarget.innerHTML = "";
    this.initializePicker(); // reset the picker
  }

  initializePicker() {
    this.picker = new Picker({
      data: async () => {
        const response = await fetch("https://cdn.jsdelivr.net/npm/@emoji-mart/data");

        return response.json();
      },
      onEmojiSelect: (emoji) => {
        this.fieldTarget.value = emoji.native;
        this.buttonTarget.innerHTML = emoji.native;
      },
      previewPosition: "none",
    });
  }

  togglePicker(event) {
    event.preventDefault();

    if (this.pickerContainerTarget.innerHTML == "") {
      this.pickerContainerTarget.appendChild(this.picker);
    } else {
      this.pickerContainerTarget.innerHTML = "";
    }
  }
}
