import { Controller } from '@hotwired/stimulus'
import Chart from 'chart.js/auto'
import 'chartjs-adapter-moment';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(ChartDataLabels);
Chart.defaults.set('plugins.datalabels', {
  display: false
});

export default class extends Controller {
  static targets = ['canvas']
  static values = {
    type: {
      type: String,
      default: 'line'
    },
    data: Object,
    options: Object
  }

  connect() {
    const element = this.hasCanvasTarget ? this.canvasTarget : this.element
    this.chart = new Chart(element.getContext('2d'), {
      type: this.typeValue,
      data: this.chartData,
      options: this.chartOptions
    })
  }

  disconnect() {
    this.chart.destroy()
    this.chart = undefined
  }

  get chartData() {
    if (!this.hasDataValue) {
      console.warn('You need to pass data as JSON to see the chart.')
    }

    return this.dataValue
  }

  get chartOptions() {
    return {
      ...this.defaultOptions,
      ...this.optionsValue
    }
  }

  get defaultOptions() {
    return {
      responsive: true,
      maintainAspectRatio: false,
      animation: false
    }
  }
}