import { Controller } from "@hotwired/stimulus";
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';

export default class extends Controller {
  static values = { fileName: String }
  static targets = ["container"]

  connect() {
  }

  download(event) {
    event.preventDefault();

    const node = this.hasContainerTarget ? this.containerTarget : this.element;
    let btn = event.target.closest('.btn') || event.target;
    let originalContent = btn.innerHTML;
    let fileName = this.fileNameValue;

    btn.innerHTML = `<i class='fa fa-spinner fa-spin'></i>`;
    btn.disabled = true;

    node.classList.add('m-0');

    htmlToImage.toPng(node, { backgroundColor: 'white' })
      .then(function (dataUrl) {
        var link = document.createElement('a');
        link.download = `${fileName}.png`;
        link.href = dataUrl;
        link.click();

        btn.innerHTML = originalContent;
        btn.disabled = false;

        node.classList.remove('m-0');
      });
  }
}
