import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  // TODO: this controller does too much, we should break it up into smaller controllers:
  // filter pane state, manipulating filter form, injecting facet counts. each of these could be a separate controller
  static targets = ['clearable', 'clearButton', 'facetCount', 'facetCountReplacement', 'filterToggle', 'filterToggleIndicator', 'filterPanel', 'savedSearchOptionsTemplate'];
  static values = { panelId: String };

  connect() {
    this.clearableTargets.forEach((target) => {
      target.addEventListener('change', this.toggleClearButton.bind(this));
      target.addEventListener('change', this.updateIndicatorCount.bind(this));
    })

    if (this.hasFilterToggleTarget) {
      this.filterToggleTarget.addEventListener('click', this.togglePanel.bind(this));
    }
  }

  disconnect() {
    this.rememberFilterPanelState(this.state);

    this.clearableTargets.forEach((target) => {
      target.removeEventListener('change', this.toggleClearButton.bind(this));
      target.removeEventListener('change', this.updateIndicatorCount.bind(this));
    })
    this.filterToggleTarget.removeEventListener('click', this.togglePanel.bind(this));
  }

  updatePanelVisibility() {
    this.filterPanelTarget.classList.toggle('d-lg-block', !this.state);

    if (this.hasFilterToggleTarget) {
      this.filterToggleTarget.classList.toggle('active', !this.state);
      this.filterToggleTarget.toggleAttribute('aria-pressed', !this.state);
    }
  }

  filterPanelTargetConnected() {
    this.state = this.getFilterPanelState();
    this.updatePanelVisibility();
  }

  togglePanel(event) {
    event.preventDefault();

    this.state = !this.state;
    this.updatePanelVisibility();
  }

  filterToggleIndicatorTargetConnected() {
    this.updateIndicatorCount();
  }

  updateIndicatorCount() {
    if (this.filterToggleIndicatorTargets.size == 0) return;

    // this.filterToggleIndicatorTarget.classList.toggle('d-none', this.allClearableInputsAreEmpty());
    this.filterToggleIndicatorTargets.forEach((target) => {
      target.textContent = this.activeFilterCount();
      target.classList.toggle('d-none', this.allClearableInputsAreEmpty());
      target.setAttribute('aria-label', `${this.activeFilterCount()} active filters`);
    });
  }

  activeFilterCount() {
    return this.clearableTargets.reduce((total, target) => {
      switch (target.type) {
        case 'checkbox':
        case 'radio':
          return total + (target.checked ? 1 : 0);
        case 'select-one':
          return total + (target.selectedIndex === 0 ? 0 : 1);
        case 'select-multiple':
          return total + (target.selectedIndex === -1 ? 0 : 1);
        case 'text':
        case 'hidden':
          return total + (target.value === '' ? 0 : 1);
        default:
          console.log('unhandled type', target.type);
          return total + 1;
      }
    }, 0);
  }

  clearButtonTargetConnected() {
    this.toggleClearButton();
  }

  toggleClearButton() {
    if (!this.clearButtonTarget) return;

    this.clearButtonTarget.classList.toggle('invisible', this.allClearableInputsAreEmpty());
    this.filterToggleIndicatorTarget.classList.toggle('d-none', this.allClearableInputsAreEmpty());
  }

  allClearableInputsAreEmpty() {
    return this.activeFilterCount() === 0;
  }

  clearAll(event) {
    event.preventDefault();

    this.clearableTargets.forEach((target) => {
      switch (target.type) {
        case 'checkbox':
        case 'radio':
          target.checked = false;
          break;
        case 'select-one':
          target.selectedIndex = 0;
          break;
        case 'select-multiple':
          target.selectedIndex = -1;
          break;
        case 'text':
        case 'hidden':
          target.value = '';
          break;
        default:
          console.log('unhandled type', target.type);
      }

      target.dispatchEvent(new Event('change'));
    })

    this.clearableTargets[0].form.requestSubmit();
  }

  facetCountTargetConnected(element) {
    // Prevent container hiding for now...

    const container = element.closest('.facet-group-container');

    if (container) {
      // find all facets in the same container
      const facetCountTargets = container.querySelectorAll('[data-search-filters-target="facetCount"]');
      const activeFacetFilters = container.querySelectorAll('input:checked').length;

      // sum the values of the facetCountTargets from their contents
      const sumOfFacetCounts = Array.from(facetCountTargets).reduce((total, element) => {
        return total + parseInt(element.dataset.facetCount);
      }, 0);

      // hide the container if the sum of the facet counts is 0 and none of the filters are active
      container.classList.toggle('d-none', (sumOfFacetCounts + activeFacetFilters === 0));
    }
  }

  localStorageKey() {
    return `filterPanelState-${this.panelIdValue}`;
  }

  rememberFilterPanelState(state) {
    const panelId = this.panelIdValue;

    localStorage.setItem(this.localStorageKey(), state);
  }

  getFilterPanelState() {
    if (!this.hasFilterToggleTarget) return true;

    const panelId = this.panelIdValue;
    const panelState = localStorage.getItem(this.localStorageKey());

    return panelState === 'true';
  }

  // Facet Count Replacement - works like a DIY Turbo stream
  facetCountReplacementTargetConnected(element) {
    this.filterPanelTarget.querySelector(`#${element.dataset.facetId}`).replaceWith(element.content);
  }

  savedSearchOptionsTemplateTargetConnected(element) {
    this.element.querySelector('#saved_search_options').replaceWith(element.content);
  }
}
