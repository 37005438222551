//
// popover.js
// Theme module
//

'use strict';

document.addEventListener("turbo:load", function() {

  //
  // Variables
  //

  var toggle = document.querySelectorAll('[data-toggle="popover"]');


  //
  // Functions
  //

  function init(toggle) {
    $(toggle).popover({
      sanitize: false
    });
  }


  //
  // Events
  //

  if (toggle) {
    init(toggle);
  }

});
