import { Controller } from "@hotwired/stimulus";
import baguetteBox from "baguettebox.js/dist/baguetteBox.js";

export default class extends Controller {
  static targets = ["form", "media"];

  connect() {
    baguetteBox.run(".gallery", { filter: /.*/i }); // filter enables urls with no extension

    if (this.hasMediaTarget && !this.regex.test(this.mediaTargetPath)) {
      this.handleError();
    }
  }

  handleError() {
    this.formTarget.requestSubmit();
  }

  get regex() {
    return /\.(jpe?g|png|gif|bmp|webp|svg|mp3)$/i;
  }

  get mediaTargetPath() {
    const url = new URL(this.mediaTarget.src);
    return url.pathname; // Returns the path part of the URL without parameters
  }
}
