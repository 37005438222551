import { Controller } from "@hotwired/stimulus"
import select2 from 'select2'

export default class extends Controller {

  connect() {
    $(this.element).select2({
      tags: true,
      tokenSeparators: [';'],
      matcher: this.matchCustom,
      language: {
        noResults: function () {
          return "Start typing...";
        }
      },
      templateResult: (item) => {
        if (item.loading || item.id == '') { return item.text }
        return "Enter to add: " + item.text
      },
    });

    // Dispatch a native event when a select2 item is selected, so that Stimulus can pick it up
    $(this.element).on('select2:select select2:unselect', (e) => {
      let event = new Event('change', { bubbles: true })
      this.element.dispatchEvent(event)
    })
  }

  matchCustom(params, data) {
    return null;
  }

  disconnect() {
    $(this.element).select2('destroy')
  }

}
