// Entry point for the build script in your package.json

// Set Some Global Includes
import './jquery.js'

import "@hotwired/turbo-rails"
import 'bootstrap';
import ClipboardJS from 'clipboard';

require("trix")
require("@rails/actiontext")


import "./autosize.js";
import "./backbutton.js";
import "./external-links.js";
import "./list.js";
import "./organizations.js";
import "./person-edit-form.js";
import "./popover.js";
import "./pull-to-refresh.js";
import "./sortable.js";
import "./tooltip.js";
import "./turn.js";
import "./wizard.js";

import {
  initClipboardJS,
} from "./utils"

import "./controllers"

// Turbo Patch for intercom
// https://github.com/intercom/intercom-rails/issues/336#issuecomment-929072944
// PATCH -->
document.addEventListener("turbo:submit-end", (event) => {
  window.turboFormSubmissionFailed = !event.detail.success

  if (window.turboFormSubmissionFailed) {
    document.dispatchEvent(new Event('turbo:visit'))
    document.dispatchEvent(new Event('turbo:before-cache'))
  }
})
document.addEventListener("turbo:render", () => {
  if (window.turboFormSubmissionFailed) {
    document.dispatchEvent(new Event('turbo:load'))
    window.turboFormSubmissionFailed = false
  }
})
// <-- PATCH

document.addEventListener("turbo:load", initClipboardJS)

// Flash messages
document.addEventListener("turbo:before-cache", function () {
  $(".toast").remove()
})

document.addEventListener("turbo:load", function () {
  $('.toast').toast('show');
})

window.$ = $;
