import ClipboardJS from 'clipboard';

export const initPluginWhenOpenModals = (init) => {
  $(document).on('shown.bs.modal', function (e) {
    init();
  })
};

export const isElementExist = (elem) => document.querySelectorAll(elem).length;

export const isElementOverflowing = (elem) => {
  const curOverflow = elem.style.overflow;

  if (!curOverflow || curOverflow === "visible")
    elem.style.overflow = "hidden";

  // Add some wiggle room to account for browser inconsistencies
  const isOverflowing = (elem.clientWidth + 1) < elem.scrollWidth
    || (elem.clientHeight + 1) < elem.scrollHeight;

  elem.style.overflow = curOverflow;

  return isOverflowing;
}

export const isElementVisible = (elem) => {
  return elem.offsetWidth > 0
    || elem.offsetHeight > 0
    || elem.getClientRects().length > 0;
}

export const initClipboardJS = () => {
  var clipboard = new ClipboardJS('.clipboard-copy-btn');
  clipboard.on('success', function (e) {
    if (e.trigger.dataset.originalTitle) {
      $(e.trigger).tooltip('hide')
        .attr('data-original-title', 'Copied!')
        .tooltip('show');
    } else {
      e.trigger.innerHTML = "<i class='fa-light fa-copy mr-2'></i> Copied!"
    }
  });
}

export const updateQueryStringParameter = (uri, key, value) => {
  var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
  var separator = uri.indexOf('?') !== -1 ? "&" : "?";
  if (uri.match(re)) {
    return uri.replace(re, '$1' + key + "=" + value + '$2');
  }
  else {
    return uri + separator + key + "=" + value;
  }
}

export const isTouchDevice = () => {
  return true == ("ontouchstart" in window || window.DocumentTouch && document instanceof DocumentTouch);
}
