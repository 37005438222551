import { Controller } from "@hotwired/stimulus"
import { isTouchDevice } from '../../utils';

export default class extends Controller {
  static targets = ["handle"]

  initialize() {
    this.boundStopResize = this.stopResize.bind(this);
    this.boundResizeSidebar = this.resizeSidebar.bind(this);
  }

  connect() {
    if (isTouchDevice()) return;

    this.restoreSidebarWidth();
  }

  disconnect() {
    document.removeEventListener("mouseup", this.boundStopResize);
  }

  startResize(e) {
    document.addEventListener("mouseup", this.boundStopResize);
    document.addEventListener("mousemove", this.boundResizeSidebar);
  }

  resizeSidebar(e) {
    e.preventDefault();

    // Make width of sidebar match mouse position
    this.element.style.width = `${e.clientX}px`;
    document.body.style.cursor = "col-resize";
    this.storeSidebarWidth();
  }

  stopResize(e) {
    document.body.style.cursor = null;
    document.removeEventListener("mousemove", this.boundResizeSidebar);
  }

  storeSidebarWidth() {
    localStorage.setItem("sidebarWidth", this.element.style.width);
  }

  restoreSidebarWidth() {
    this.element.style.width = localStorage.getItem("sidebarWidth") || "250px";
  }
}

