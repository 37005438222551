import { Controller } from "@hotwired/stimulus";
import Popper from "popper.js";

export default class extends Controller {
  static targets = ["hoverzone"];
  static values = {
    url: String,
    placement: {
      type: String,
      default: "bottom-start",
    },
    offset: {
      type: String,
      default: "0, 0",
    },
  };

  connect() {
    if (this.hasHoverzoneTarget) {
      this.hoverzoneTargets.forEach((target) => {
        target.addEventListener("mouseenter", this.delayedShow.bind(this));
      });
    } else {
      this.element.addEventListener("mouseenter", this.delayedShow.bind(this));
    }
  }

  disconnect() {
    if (this.popoverContent) this.hide();
  }

  delayedShow(e) {
    this.showTimeout = setTimeout(() => this.show(e), 250);
    e.target.addEventListener("mouseleave", this.clearShowTimeout.bind(this));
  }

  clearShowTimeout() {
    clearTimeout(this.showTimeout);
  }

  show(e) {
    if (this.popoverContent) this.hide();

    this.setPopoverContent();

    this.popper = new Popper(this.element, this.popoverContent, {
      placement: this.placementValue,
      modifiers: {
        offset: {
          offset: this.offsetValue,
        },
      },
    });

    this.mouseEnter = false;
    this.popoverContent.addEventListener("mouseenter", () => (this.mouseEnter = true));
    this.popoverContent.addEventListener("mouseleave", this.hide.bind(this));

    e.target.addEventListener("mouseleave", () => {
      setTimeout(() => {
        if (!this.mouseEnter) this.hide();
      }, 100);
    });
  }

  hide() {
    this.popper.destroy();
    this.popoverContent.remove();
  }

  setPopoverContent() {
    let element = document.createElement("div");

    element.innerHTML = `<turbo-frame id="hovercard" src="${this.urlValue}&placement=${this.placementValue}"></turbo-frame>`;
    element.style.zIndex = 2000;
    document.body.appendChild(element);

    this.popoverContent = element;
  }
}
