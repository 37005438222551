import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['fieldset', 'dropdown', 'template', 'wrapper']

  connect() {
    this.refreshDropdown()
  }

  fieldsetTargetConnected(fieldset) {
    if (fieldset.hidden) { this.disableFieldsetInputs(fieldset) }
  }

  refreshDropdown() {
    let showDivider = false
    this.dropdownTarget.innerHTML = ''
    this.fieldsetTargets.filter(fieldset => !fieldset.dataset.newRecord).forEach(fieldset => {
      if (fieldset.hidden) {
        this.dropdownTarget.innerHTML += `
          <a href="#" class="dropdown-item" data-fieldset-id="${fieldset.id}" data-action="fieldset-manager#addFieldset">
            ${fieldset.dataset.fieldsetLabel || fieldset.id}
          </a>
        `
        showDivider = true;
      }
    })

    if (showDivider) {
      this.dropdownTarget.innerHTML += `<div class="dropdown-divider"></div>`
    }
  }

  newFieldset(event) {
    event.preventDefault()

    var newElement = this.templateTarget.content.firstElementChild.cloneNode(true)
    newElement.innerHTML = newElement.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())

    this.wrapperTarget.insertAdjacentElement('beforeend', newElement)
  }

  addFieldset(event) {
    event.preventDefault()
    const fieldset = document.getElementById(event.currentTarget.dataset.fieldsetId)

    this.showFieldset(fieldset)
    this.enableFieldsetInputs(fieldset)
    this.unmarkForDestruction(fieldset)
    this.refreshDropdown()
  }

  removeFieldset(event) {
    event.preventDefault()
    const fieldset = document.getElementById(event.currentTarget.dataset.fieldsetId)

    if (fieldset.dataset.newRecord) {
      fieldset.remove()
      return
    }

    this.hideFieldset(fieldset)
    this.disableFieldsetInputs(fieldset)
    this.markForDestruction(fieldset)
    this.refreshDropdown()
  }

  hideFieldset(fieldset) {
    fieldset.hidden = true
  }

  showFieldset(fieldset) {
    fieldset.hidden = false
  }

  disableFieldsetInputs(fieldset) {
    fieldset.querySelectorAll("input:not([name*='_destroy']), select, textarea").forEach(input => { input.disabled = true })
  }

  enableFieldsetInputs(fieldset) {
    fieldset.querySelectorAll("input:not([name*='_destroy']), select, textarea").forEach(input => { input.disabled = false })
  }

  markForDestruction(fieldset) {
    const input = fieldset.querySelector("input[name*='_destroy']")
    if (input) {
      input.disabled = false
      input.value = 1
    }
  }

  unmarkForDestruction(fieldset) {
    const input = fieldset.querySelector("input[name*='_destroy']")
    if (input) { input.value = 0 }
  }
}