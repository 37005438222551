import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input"];

  initialize() {
    this.boundConfirmUnload = this.confirmUnload.bind(this);
    this.boundConfirmNavigation = this.confirmNavigation.bind(this);
    this.disableWarnings = this.disableWarnings.bind(this);
    this.enableWarnings = this.enableWarnings.bind(this);
  }

  connect() {
    this.form.addEventListener("submit", this.disableWarnings);
    this.initialFormData = this.formData();
    this.form.classList.add("dirty-form");
  }

  disconnect() {
    this.disableWarnings();
  }

  update() {
    if (this.hasUnsavedChanges()) {
      this.enableWarnings();
      this.form.classList.add("dirty-form--dirty");
    } else {
      this.disableWarnings();
      this.form.classList.remove("dirty-form--dirty");
    }
  }

  discard() {
    this.disableWarnings();
    Turbo.clearCache();
    Turbo.visit(window.location.toString());
  }

  enableWarnings() {
    addEventListener("beforeunload", this.boundConfirmUnload);
    addEventListener("turbo:before-visit", this.boundConfirmNavigation);
  }

  disableWarnings() {
    removeEventListener("beforeunload", this.boundConfirmUnload);
    removeEventListener("turbo:before-visit", this.boundConfirmNavigation);
  }

  confirmNavigation(e) {
    const userWantsToLeave = confirm("Changes that you made may not be saved. Do you want to leave?");
    if (!userWantsToLeave) return e.preventDefault();
  }

  confirmUnload(e) {
    e.returnValue = "Changes that you made may not be saved. Do you want to leave?";
  }

  hasUnsavedChanges() {
    return !this.formDataIsEqual(this.initialFormData, this.formData())
  }

  formDataIsEqual(formData1, formData2) {
    return JSON.stringify([...formData1.entries()]) === JSON.stringify([...formData2.entries()])
  }

  formData() {
    return new FormData(this.form);
  }

  get form() {
    return this.element.closest("form");
  }
}
