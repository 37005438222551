import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["iframe"]
  static values = { emailBody: String }


  connect() {
    this.iframeTarget.contentWindow.document.write(this.emailBodyValue);
    this.iframeTarget.contentWindow.document.close()
    this.iframeTarget.height = this.contentHeight();
  }

  contentHeight() {
    return `${this.iframeTarget.contentWindow.document.body.scrollHeight}px`;
  }
}
