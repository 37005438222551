import { Controller } from "@hotwired/stimulus";
import { isElementOverflowing } from "../utils";

export default class extends Controller {
  connect() {
    if (isElementOverflowing(this.element)) {
      this.element.classList.add('overflowing');
    }
  }
}
