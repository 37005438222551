import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['fullName', 'firstName', 'lastName']

  connect() {
    this.firstNameTarget.readOnly = true
    this.firstNameTarget.style.cursor = 'default'
    this.lastNameTarget.readOnly = true
    this.lastNameTarget.style.cursor = 'default'
  }

  editFields(event) {
    event.preventDefault()
    this.firstNameTarget.readOnly = false
    this.firstNameTarget.style.cursor = 'text'
    this.lastNameTarget.readOnly = false
    this.lastNameTarget.style.cursor = 'text'
    this.firstNameTarget.focus()
  }

  clearFields(event) {
    event.preventDefault()
    this.firstNameTarget.value = ''
    this.lastNameTarget.value = ''
  }

  // break full name into first and last name
  updateFields(event) {
    event.preventDefault()
    const fullName = this.fullNameTarget.value
    let names = fullName.split(' ')
    this.firstNameTarget.value = names.shift()
    this.lastNameTarget.value = names.join(' ') || ''
  }

}
