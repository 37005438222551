document.addEventListener("turbo:load", function() {
  var wto;
  $("input[name='organization[website]']").on('change keyup paste', function () {
    clearTimeout(wto);
    wto = setTimeout(function() {
      var website = $("input[name='organization[website]']").val();
      $("#clearbit-logo").show();
      $("#clearbit-logo").attr("src", "https://logo.clearbit.com/"+ website)
    }, 500);
  });
});
