import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    $(this.element).toast('show');
  }

  disconnect() {
    $(this.element).toast('hide');
  }
}
