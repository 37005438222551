import { Controller } from "@hotwired/stimulus";
import Colcade from "colcade"

export default class extends Controller {
  static targets = ["entries"]

  connect() {
    this.initializeColcade()
    document.addEventListener('DOMContentLoaded', this.refreshGrid.bind(this))
  }

  refreshGrid() {
    if (this.grid == null) return

    this.grid.append(this.entriesTarget.children)
  }

  initializeColcade() {
    this.grid = new Colcade(this.element, {
      columns: '.colcade-col',
      items: '.colcade-item'
    });
  }
}
