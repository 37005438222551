import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["entry", "activeEntry"]
  static values = { activeClass: String }

  connect() {
    this.applyActiveClass()
    this.scrollToActiveEntry()
  }

  toggleActiveEntry() {
    this.makeAllInactive()
    this.setActiveEntry(event.currentTarget.dataset.entryId)
    this.applyActiveClass()
    this.scrollToActiveEntry()
  }

  applyActiveClass() {
    const activeEntry = this.activeEntry()
    if (activeEntry === undefined) return

    const classNames = this.activeClassValue.split(' ')

    classNames.forEach((className) => {
      this.entryTargets.forEach((entry) => { entry.classList.remove(className) })
      activeEntry.classList.add(className)
    })
  }

  scrollToActiveEntry() {
    const activeEntry = this.activeEntry()

    if (activeEntry === undefined) return

    activeEntry.scrollIntoView({ behavior: 'smooth', inline: 'nearest', block: 'nearest' })
  }

  setActiveEntry(entryId) {
    this.entryTargets.find(entry => entry.id === entryId).dataset.active = true
  }

  makeAllInactive() {
    this.entryTargets.forEach((entry) => { entry.dataset.active = false })
  }

  activeEntry() {
    return this.entryTargets.find(entry => (entry.dataset.active === 'true'))
  }
}
