import TurndownService from 'turndown';
import Quill from 'quill'
import MagicUrl from 'quill-magic-url'
import "quill-mention";

const updateEditCursorPosition = (editor) => {
  const editCursorPosition = editor.getSelection();

  if (editCursorPosition) {
    window.editCursorPosition = editCursorPosition;
  }
};

const convertHtmlToMarkdown = (content) => {
  const htmlToMarkdown = new TurndownService({
    emDelimiter: '*'
  });

  htmlToMarkdown.addRule('strikethrough', {
    filter: ['del', 's', 'strike'],
    replacement: function (content) {
      return `~~${content}~~`
    }
  })

  htmlToMarkdown.addRule('underline', {
    filter: ['u'],
    replacement: function (content) {
      return `_${content}_`
    }
  })

  // Escape parentheses in URLs
  // Overrides URL rule here https://github.com/mixmark-io/turndown/blob/4499b5c313d30a3189a58fdd74fc4ed4b2428afd/src/commonmark-rules.js#L145-L152
  // We don't care about the Title attribute in links so I've omitted it for simplicity.
  htmlToMarkdown.addRule('inlineLink', {
    filter: function (node, options) {
      return (
        options.linkStyle === 'inlined' &&
        node.nodeName === 'A' &&
        node.getAttribute('href')
      )
    },
    replacement: function (content, node) {
      var href = node.getAttribute('href').replace(/(?=[()])/g, '\\');

      return '[' + content + '](' + href + ')'
    }
  })

  return htmlToMarkdown.turndown(content);
}

const updateInputWithMarkdown = (input, content) => {
  const markdown = convertHtmlToMarkdown(content)
  input.val(markdown);
  input.trigger('triggerUpdateDraft');
};

export const setEditorContent = (content) => {
  const htmlContent = content
  const delta = window.postEditor.clipboard.convert(htmlContent);
  window.postEditor.setContents(delta, 'silent');
};

export const createPostEditor = (element) => {
  const insertMentionLink = ({ postEditor, index, id, value }) => {
    const mentionLink = `/profiles/${id}`;
    postEditor.insertText(index, value, { link: mentionLink });

    $(`a[href="${mentionLink}"]`).addClass(`mention ${index}`);
  };

  Quill.register('modules/magicUrl', MagicUrl)

  const postEditor = new Quill(element, {
    modules: {
      toolbar: [
        [{ 'header': 1 }, { 'header': 2 }],
        ['bold', 'italic', 'underline', 'strike'],
        ['link'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ['clean'],
      ],
      magicUrl: true,
      mention: {
        allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
        mentionDenotationChars: ["@"],
        isolateCharacter: true,
        source: function (searchTerm, renderList) {
          $.getJSON(element.dataset.mentionableUsersSrc, {
            term: searchTerm
          })
            .done(function (mentionableUsers) {
              window.quillMentionSearchTerm = searchTerm;
              renderList(mentionableUsers, searchTerm);
              return;
            });
        },
        onSelect(item) {
          const { id, value } = item;
          const searchTermLength = window.quillMentionSearchTerm.length;
          const index = (window.editCursorPosition.index - searchTermLength);

          postEditor.deleteText(index, searchTermLength);
          insertMentionLink({ postEditor, index, id, value });
        }
      },
    },
    formats: [
      'size',
      'header',
      'bold',
      'italic',
      'underline',
      'strike',
      'link',
      'list',
      'blockquote',
      'list',
    ],
    theme: 'snow',
    placeholder: element.dataset.placeholder
  });

  window.postEditor = postEditor;
  return postEditor;
};

export const handlePostMentions = (editor) => {
  editor.on('editor-change', () => {
    updateEditCursorPosition(editor);

    const input$ = $('#post-body');
    const content = $(".ql-editor").html();
    updateInputWithMarkdown(input$, content);
  });
};
