export const storage = (str) => {
  return localStorage.getItem(str);
}

export const parsedStorage = (str) => {
  return JSON.parse(storage(str));
}

export const storageIsPresent = (str) => {
  return storage(str) != null && storage(str).length > 0;
}
