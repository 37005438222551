import { Controller } from "@hotwired/stimulus";
import Colcade from "colcade"

export default class extends Controller {
  static targets = ["setTime", "removeTime", "timeInput"]


  removeTime(e) {
    e.preventDefault()
    const field = this.timeInputTarget

    field.classList.add('d-none')
    field.disabled = true

    this.setTimeTarget.classList.remove('d-none')
    this.removeTimeTarget.classList.add('d-none')
  }

  setTime(e) {
    e.preventDefault()
    const field = this.timeInputTarget

    field.classList.remove('d-none')
    field.disabled = false

    this.setTimeTarget.classList.add('d-none')
    this.removeTimeTarget.classList.remove('d-none')
  }
}
