import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  connect() {
    this.element.addEventListener("turbo:before-frame-render", async (event) => {
      event.preventDefault()

      await this.animateOut()

      event.detail.resume()
    })
  }

  async animateOut() {
    this.element.addClassName("animate__animated animate__fadeOut")
    await new Promise(resolve => setTimeout(resolve, 300))
  }

  disconnect() {
    this.element.removeEventListener("turbo:before-frame-render")
    this.element.removeClassName("animate__animated animate__fadeOut")
  }
}
