import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["selection", "message"]

  connect() {
    document.addEventListener('selectionchange', this.toggleMessage.bind(this))
  }

  getSelectedText() {
    let selection = document.getSelection()
    return selection.toString().trim().length > 0 && this.selectionTarget.contains(selection.anchorNode) && this.selectionTarget.contains(selection.focusNode) ? selection.toString() : false
  }

  toggleMessage() {
    if (!this.hasMessageTarget) return
    this.messageTarget.hidden = this.getSelectedText() ? false : true
  }

  submit(event) {
    event.preventDefault();

    let url_object = new URL(event.target.href)

    if (this.getSelectedText()) {
      url_object.searchParams.append('highlighted_text', this.getSelectedText())
    } else {
      url_object.searchParams.delete('highlighted_text')
    }

    // Avoid query param / cookie overflow by converting to a hidden form and submitting
    var form = document.createElement("FORM");
    form.action = url_object.pathname
    form.method = "POST"
    form.dataset.turboFrame = 'modal'
    for (var pair of url_object.searchParams.entries()) {
      var input = document.createElement("INPUT")
      input.type = 'hidden'
      input.name = pair[0]
      input.value = pair[1]
      form.appendChild(input)
    }

    document.body.appendChild(form)
    form.requestSubmit();
  }
}
