import { Controller } from "@hotwired/stimulus";
import Reveal from "stimulus-reveal-controller"

export default class extends Reveal {

  static values = {
    disableInputs: { type: Boolean, default: false }
  }

  // This extends the Reveal controller to also disable inputs when the item is hidden if you set the disableInputsValue to true
  // This is useful when you want to hide some inputs and prevent them from being submitted when the item is hidden
  toggleInputs() {
    this.itemTargets.forEach((item) => {
      // Find all inputs in the item and toggle their disabled state
      item.querySelectorAll('input, select, textarea').forEach((input) => {
        input.disabled = input.disabled ? false : true
      })
    })
  }

  showInputs() {
    this.itemTargets.forEach((item) => {
      // Find all inputs in the item and toggle their disabled state
      item.querySelectorAll('input, select, textarea').forEach((input) => {
        input.disabled = false
      })
    })
  }

  hideInputs() {
    this.itemTargets.forEach((item) => {
      // Find all inputs in the item and toggle their disabled state
      item.querySelectorAll('input, select, textarea').forEach((input) => {
        input.disabled = true
      })
    })
  }

  toggle() {
    if (this.disableInputsValue) { this.toggleInputs() }
    super.toggle()
  }

  show() {
    if (this.disableInputsValue) { this.showInputs() }
    super.show()
  }

  hide() {
    if (this.disableInputsValue) { this.hideInputs() }
    super.hide()
  }
}
