// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "@hotwired/stimulus"
import ConditionalFields from 'conditional-fields';

export default class extends Controller {

  connect() {
    new ConditionalFields(this.element, {
      onShow: this.onShow,
      onHide: this.onHide
    })

    // https://www.npmjs.com/package/conditional-fields
    // NOTE: this library doesn't support evaluating array inputs, like multiple selects or checkboxes
    // You can also provide your own functions to show and hide form fields:
    // new ConditionalForm(this.element, {
    //   onShow: (root) => root.closest('div.row').style.display = 'block',
    //   onHide: (root) => root.closest('div.row').style.display = 'none'
    // });
  }

  onShow = function (root) {
    root.closest('.conditional_container').style.display = 'block'
  }

  onHide = function (root) {
    root.closest('.conditional_container').style.display = 'none'
  }

  disconnect() {
    // some sort of destroy?
  }
}
