import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["sidebar", "link"]

  initialize() {
    // Bind functions so they can be added and removed as event listeners
    // https://stackoverflow.com/a/57318098
    this.boundCloseOnOutsideTouch = this.closeOnOutsideTouch.bind(this);
    this.boundCloseOnLink = this.closeOnLink.bind(this);
  }

  connect() {
    if (window.sidebarOpen) {
      this.openSidebar();
    }
  }

  disconnect() {
    this.linkTargets.forEach((link) => {
      link.removeEventListener("click", this.boundCloseOnLink);
    });
  }

  openSidebar() {
    window.sidebarOpen = true;

    this.sidebarTarget.classList.add("active");
    this.sidebarTarget.classList.add("shadow-lg");

    // Close sidebar when clicking outside of it
    document.addEventListener("touchend", this.boundCloseOnOutsideTouch);
    document.addEventListener("mouseup", this.boundCloseOnOutsideTouch);

    // Close sidebar when clicking on any link in it
    this.linkTargets.forEach((link) => {
      link.addEventListener("click", this.boundCloseOnLink);
    });
  }

  closeSidebar() {
    window.sidebarOpen = false;
    this.sidebarTarget.classList.remove("active");
    this.sidebarTarget.classList.remove("shadow-lg");

    // Remove event listener
    document.removeEventListener("touchend", this.boundCloseOnOutsideTouch);
    document.removeEventListener("mouseup", this.boundCloseOnOutsideTouch);
  }

  closeOnOutsideTouch(e) {
    if (!this.sidebarTarget.contains(e.target)) {
      e.preventDefault();
      this.closeSidebar();
    }
  }

  closeOnLink(e) {
    e.preventDefault();

    this.closeSidebar();

    // Wait for sidebar to close before following link
    const visit = (elem) => { Turbo.visit(elem.href) }
    setTimeout(visit, 200, e.currentTarget);
  }

}
