import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["keywordInput", "booleanInput"]

  connect() {
    this.booleanTouchedByUser = false

    if (this.booleanInputTarget.value == "") {
      this.updateBoolean()
    }
  }

  touchBoolean(event) {
    event.target.value == this.convertedAdvancedQuery() ? this.booleanTouchedByUser = false : this.booleanTouchedByUser = true
  }

  convertedAdvancedQuery() {
    return this.keywordValues.map((keyword) => {
      if (this.isExactQuery(keyword)) {
        return keyword
      } else if (this.isBooleanQuery(keyword) && this.keywordValues.length > 1) {
        return `(${keyword})`
      } else if (this.isMultiWordQuery(keyword)) {
        return `"${keyword}"`
      } else {
        return keyword
      }
    }).join(" OR ")
  }

  get keywordValues() {
    if (!this.hasKeywordInputTarget) { return [] }

    return this.keywordInputTargets.filter(input => (input.value != "" && input.closest("[data-keyword-wrapper]").hidden == false)).map(input => input.value.trim())
  }

  isBooleanQuery(query) {
    return query.includes(" OR ") || query.includes(" AND ") || query.includes(" NOT ") || query.includes(" NEAR ") || query.includes(" NEAR/")
  }

  isExactQuery(query) {
    // query is exact if it is wrapped in quotes
    return query.startsWith('"') && query.endsWith('"')
  }

  isMultiWordQuery(query) {
    if (this.isBooleanQuery(query) || this.isExactQuery(query)) {
      return false
    } else {
      return query.includes(" ")
    }
  }

  updateBoolean() {
    // Return if the user has provided a custom query
    if (this.booleanTouchedByUser && this.booleanInputTarget.value != "") {
      return
    }

    this.booleanInputTarget.value = this.convertedAdvancedQuery()
  }
}
