import { Controller } from "@hotwired/stimulus"
import { isElementVisible } from "../utils"

export default class extends Controller {
  static targets = ['tabsContainer', 'tab', 'activeTab', 'dropdown']

  connect() {
    // TODO: currently this doesnt work
    this.collapseTabs()
    console.error('collapsible tabs not currently working')
  }

  disconnect() {
  }

  resize(event) {
    this.collapseTabs()
  }

  collapseTabs() {
    var navHeight = this.tabsContainerTarget.clientHeight
    var maxHeight = this.tabTargets[0].clientHeight
    var dropdownMenu = this.dropdownTarget.querySelector('.dropdown-menu')
    if (navHeight > maxHeight) {
      this.dropdownTarget.style.display = 'initial'

      while (navHeight > maxHeight) {
        //  add child to dropdown
        var visible_children = this.tabTargets.filter(isElementVisible);
        dropdownMenu.prepend(visible_children[visible_children.length - 1])
        visible_children[visible_children.length - 1].style.display = 'none'
        navHeight = this.tabsContainerTarget.clientHeight
      }
    } else {
      var hidden_children = this.tabTargets.filter(el => isElementVisible(el) == false);
      if (hidden_children.length === 0) {
        this.dropdownTarget.style.display = 'none'
      }

      while (navHeight < maxHeight && dropdownMenu.children.length > 0 && hidden_children.length > 0) {
        //  remove child from dropdown
        dropdownMenu.removeChild(dropdownMenu.children[0])
        hidden_children[hidden_children.length - 1].style.display = 'initial'
        navHeight = this.tabsContainerTarget.clientHeight
        hidden_children = this.tabTargets.filter(el => isElementVisible(el) == false);
      }
    }
  }
}
