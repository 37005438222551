import { Controller } from "@hotwired/stimulus";

// TODO: make generic or add namespace if / when using for another screen
export default class extends Controller {
  static targets = ["form"]

  clear(event) {
    event.preventDefault();

    const inputs = this.formTarget.elements

    for (var i = 0; i < inputs.length; i++) {
      if (inputs[i].type === "checkbox") {
        if (inputs[i].checked) {
          inputs[i].click();
        }
      } else {
        if (inputs[i].type === "select-multiple") {
          inputs[i].options.length = 0;
        }
      }
    }
  }
}
