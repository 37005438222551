import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["topic",
                    "topicTab",
                    "keywords",
                    "sources",
                    "nameInput",
                    "hiddenNameInput",
                    "emojiInput",
                    "emojiButton"
                  ]

  update(e) {
    // return if change event is from the name input
    if (this.elementIsNameInput(e.target)) return

    // set the Emoji if the change event is from the topic input
    if (this.elementIsTopicInput(e.target)) {
      this.updateEmoji()
    }

    this.updateName()
  }

  elementIsNameInput(el) {
    return [this.nameInputTarget, this.hiddenNameInputTarget].includes(el)
  }

  elementIsTopicInput(el) {
    return [this.topicTarget, this.topicTabTarget].includes(el)
  }

  updateName() {
    this.hiddenNameInputTarget.value = this.suggestedName()
    this.nameInputTarget.placeholder = this.suggestedName()
  }

  updateEmoji() {
    this.emojiInputTarget.value = this.suggestedEmoji()
    this.emojiButtonTarget.innerHTML = this.suggestedEmoji()
  }

  suggestedEmoji() {
    if (this.topicTarget.value && this.topicTabTarget.checked) {
      const selection =  this.topicTarget.options[this.topicTarget.selectedIndex].text;
      // The first two characters are the emoji, so we remove them
      return selection.slice(0, 2)
    } else {
      return "🌐"
    }
  }

  suggestedName() {
    if (this.topicTarget.value && this.topicTabTarget.checked) {
      const selection =  this.topicTarget.options[this.topicTarget.selectedIndex].text;
      // emojis can be more than one character long so we should split on the first space
      let [_emoji, ...topicName] = selection.split(" ")
      return topicName.join(" ")
    } else if (this.keywordsTarget.value) {
      return this.keywordsTarget.value
    } else if (this.sourcesTarget.value) {
      const selection =  this.sourcesTarget.options[this.sourcesTarget.selectedIndex].text;

      return selection
    } else {
      return ""
    }
  }
}
