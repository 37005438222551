import { Controller } from "@hotwired/stimulus";
import { parsedStorage, storageIsPresent } from "../utils/local_storage_utils"

export default class extends Controller {
  static values = {
    draftId: String
  }

  connect() {
    let drafts = this.allDrafts()
    delete drafts[this.draftIdValue]

    localStorage.setItem('advoc8_post', JSON.stringify(drafts));
  }

  allDrafts() {
    return parsedStorage('advoc8_post') || {}
  }
}
