import { Controller } from "@hotwired/stimulus";
import select2 from "select2";
import DOMPurify from "dompurify";

export default class extends Controller {
  static values = {
    type: { type: String, default: "standard" },
    template: { type: String, default: "standard" },
    noResultsMsg: { type: String, default: "<span>No results found</span>" },
    customTags: { type: Boolean, default: false },
  };

  templateResult = (item) => {
    if (item.loading || item.id == "") {
      return item.text;
    }

    let template = selectTemplates[this.templateValue].result(item);

    return $(DOMPurify.sanitize(`
      <span class='select2-${this.templateValue}-result'>
        ${template}
      <span>
    `, { ALLOWED_TAGS: ["span", "i", "kbd", "small", "br", "div"] }));
  };

  templateSelection = (item) => {
    if (item.loading || item.id == "") {
      return item.text;
    }

    let template = selectTemplates[this.templateValue].selection(item);

    return $(DOMPurify.sanitize(`
      <span class='select2-${this.templateValue}-selection'>
        ${template}
      <span>
    `, { ALLOWED_TAGS: ["span", "i"] }));
  };

  getDefaultOptions(el) {
    return {
      minimumResultsForSearch: 5,
      templateResult: this.templateResult,
      templateSelection: this.templateSelection,
      language: {
        noResults: () => {
          return $(this.sanitizedHTML(this.noResultsMsgValue));
        },
      },
      matcher: customMatcher,
    };
  }

  initialize() {
    select2();
  }

  connect() {
    const el = this.element;

    let elementOptions = el.dataset.options ? JSON.parse(el.dataset.options) : el.dataset;
    let defaultOptions = this.getDefaultOptions(el);

    // Tags variant (multiple strings)
    if (this.typeValue === "tags") {
      defaultOptions.tags = true;
      defaultOptions.minimumResultsForSearch = 0;
      defaultOptions.multiple = true;
      defaultOptions.tokenSeparators = [";"];
    }

    // Tag variant (single string)
    if (this.typeValue === "tag") {
      defaultOptions.tags = true;
      defaultOptions.minimumResultsForSearch = 0;
      defaultOptions.allowClear = true;
    }

    const options = { ...elementOptions, ...defaultOptions };
    const isSelectInitialized = $(el).hasClass("select2-hidden-accessible");

    if (!isSelectInitialized) {
      $(el).select2(options);
      $(el).on("select2:unselecting", function (e) {
        var self = $(this);
        setTimeout(function () {
          self.select2("close");
        }, 0);
      });

      $(el).on("select2:select select2:clear select2:unselect", function () {
        const event = new Event("change", { bubbles: true }); // fire a native event
        this.dispatchEvent(event);
      });
    }

    $(el).on("select2:select", this.handleSelect.bind(this));
  }

  sanitizedHTML(html) {
    return DOMPurify.sanitize(html);
  }

  handleSelect() {
    if (this.element.dataset.closeOnSelect === "false") {
      this.clearSearchInput();
    }
  }

  clearSearchInput() {
    const searchInput = this.element.nextSibling.querySelector(".select2-search__field");

    if (searchInput !== undefined && searchInput !== null) {
      searchInput.value = " ";
    }
  }
}

// This matcher function adds support for:
// - searching nested options (optgroup)
// - chaining multiple partial terms (using AND operator) searching 'this thing' will return a record labeled 'this really great thing'
// NOTE: matcher is not used when using ajax
function customMatcher(params, data) {
  data.parentText = data.parentText || "";
  const stimulusElement = data.element.parentElement;

  // Always return the object if there is nothing to compare
  if ($.trim(params.term) === "") {
    return data;
  }

  // Do a recursive check for options with children
  if (data.children && data.children.length > 0) {
    // Clone the data object if there are children
    // This is required as we modify the object to remove any non-matches
    var match = $.extend(true, {}, data);

    // Check each child of the option
    for (var c = data.children.length - 1; c >= 0; c--) {
      var child = data.children[c];
      child.parentText += data.parentText + " " + data.text;

      var matches = customMatcher(params, child);

      // If there wasn't a match, remove the object in the array
      if (matches == null) {
        match.children.splice(c, 1);
      }
    }

    // If any children matched, return the new object
    if (match.children.length > 0) {
      return match;
    }

    // If there were no matching children, check just the plain object
    return customMatcher(params, match);
  }

  // If the typed-in term matches the text of this term, or the text from any
  // parent term, then it's a match.
  var original = (data.parentText + " " + data.text).toUpperCase().trim();
  var terms = params.term.toUpperCase().split(" ");

  // Check if the text contains the term
  const hasCustomTags = stimulusElement.dataset.select2CustomTagsValue === "true";

  if (terms.every((term) => (hasCustomTags ? original === term : original.includes(term)))) {
    return data;
  }

  // If it doesn't contain the term, don't return anything
  return null;
}

const selectTemplates = {
  standard: {
    selection: function (item) {
      return item.text;
    },
    result: function (item) {
      let subtitle = item.subtitle || item.element?.dataset?.subtitle;
      if (!subtitle) {
        return item.text;
      } else {
        return `
          ${item.text}
          <br/>
          <small class='text-muted'>${subtitle}</small>
        `;
      }
    },
  },
  "tracking-field": {
    selection: function (item) {
      let color = item.color || item.element?.dataset?.color || "transparent";

      return `
        <i class="fas fa-circle mr-2" style="color: ${color}"></i>
        ${item.text}
      `;
    },
    result: function (item) {
      let color = item.color || item.element?.dataset?.color || "transparent";

      return `
        <i class="fas fa-circle mr-2" style="color: ${color}"></i>
        ${item.text}
      `;
    },
  },
  issue: {
    selection: function (item) {
      return item.text;
    },
    result: function (item) {
      let is_private = item.private || item.element?.dataset?.private || false;
      let issue_type = item.issue_type || item.element?.dataset?.issueType;
      let subtitle = is_private ? `${issue_type} - <i class='far fa-lock mr-2'></i>Private` : issue_type;

      return `
        ${item.text}
        <br/>
        <small>${subtitle}</small>
      `;
    },
  },
  tags: {
    selection: function (item) {
      return item.text;
    },
    result: function (item) {
      return `<span>Press <kbd class='bg-light text-dark'>Enter</kbd> to add: ${item.text}</span>`;
    },
  },
  detailed: {
    selection: (item) => {
      const dataset = item.element.dataset;

      return `<i class="${dataset.icon} mr-3"></i>${dataset.label}`;
    },
    result: (item) => {
      const dataset = item.element.dataset;

      return `
        <div class='d-flex align-items-baseline'>
          <i class="${dataset.icon} mr-3"></i>
          <div>
            ${dataset.label}
            <small class="d-block text-muted">${dataset.description}</small>
          </div>
        </div>
        `;
    },
  },
};
