import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  preview(event) {
    event.preventDefault();
    const url = event.target.href;
    const height = window.innerHeight;
    window.open(url, "printPreviewWindow", `width=1060,height=${height}`);
  }

  print() {
    window.print();
  }

  close() {
    const printPreviewWindow = window.open("", "printPreviewWindow");
    printPreviewWindow.close();
  }
}
