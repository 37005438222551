//
// bootstrap.js
//

'use strict';

const initBackButton = () => {

  //
  // Variables
  //

  var backToggle = document.querySelectorAll('[data-toggle="backButton"]');


  //
  // Functions
  //

  function goBack() {
    window.history.back();
  }


  //
  // Events
  //


  if (backToggle) {
    [].forEach.call(backToggle, function(el) {
      el.addEventListener('click', function(e) {
        e.preventDefault();
        el.setAttribute("disabled", "disabled");
        goBack();
      });
    });
  }

};

document.addEventListener("turbo:load", function () {
  initBackButton();
})
