import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["mainHeader", "stickyHeader"];

  scroll() {
    if (this.element.scrollTop > this.triggerPosition()) {
      this.stickyHeaderTarget.classList.remove("d-none");
    } else {
      this.stickyHeaderTarget.classList.add("d-none");
    }
  }

  triggerPosition() {
    return this.mainHeaderTarget.offsetTop + this.mainHeaderTarget.offsetHeight - 50;
  }
}
