//
// tooltip.js
// Theme module
//
'use strict';

document.addEventListener("turbo:before-cache", function () {
  var toggle = document.querySelectorAll('[data-toggle="tooltip"]');
  if (toggle) {
    $(toggle).tooltip('hide');
    $(toggle).tooltip('disable');
  }
});

document.addEventListener("turbo:load", function () {

  //
  // Variables
  //

  var toggle = document.querySelectorAll('[data-toggle="tooltip"]');


  //
  // Functions
  //

  function init(toggle) {
    $(toggle).tooltip();
  }


  //
  // Events
  //

  if (toggle) {
    init(toggle);
  }

});
