'use strict';
import { Sortable } from '@shopify/draggable';

const init = () => {
  const containerSelector = '#sortable-container';
  const containers = document.querySelectorAll(containerSelector);

  if (containers.length === 0) {
    return false;
  }

  const sortable = new Sortable(containers, {
    draggable: '.sortable-item',
    handle: '.sort-handle',
    mirror: {
      constrainDimensions: true,
    },
  });

  sortable.on('sortable:stop', (e) => {
    const updateForm = $(e.dragEvent.data.originalSource).find('form')[0];

    if (updateForm) {
      const positionField = $(updateForm).find('input[class="position-field"]')
      positionField.val(e.newIndex + 1);
      updateForm.requestSubmit();
    }
  })

  return sortable;
};

document.addEventListener("turbo:load", function () {
  init();
});
