import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['field', 'dropdown', 'divider']

  connect() {
    this.divider()
  }

  toggle(event) {
    event.preventDefault()
    const option = event.target

    this.fieldTargets.forEach(field => {
      if (field.hidden && field.id === option.dataset.fieldId && option.dataset.fieldId !== '') {
        field.hidden = false
        option.hidden = true
      }
    })
    this.divider()
  }

  fieldTargetConnected(field) {
    this.dropdownTarget.innerHTML += `
      <button type="button" class="dropdown-item py-3" data-field-id="${field.id}" data-action="click->field-manager#toggle" ${field.hidden ? '' : 'hidden'}>
         ${field.firstElementChild.innerText}
      </button>`
    this.importantFields(field)
  }

  divider() {
    const totalOptions = this.dropdownTarget.querySelectorAll('button').length
    const hiddenOptions = this.dropdownTarget.querySelectorAll('button[hidden]').length
    this.dividerTarget.hidden = totalOptions === hiddenOptions ? true : false
  }

  importantFields(field) {
    if (field.dataset.important == 'true') {
      const options = this.dropdownTarget.querySelectorAll('button')
      options.forEach(option => { if (option.dataset.fieldId === field.id) option.hidden = true })
      field.hidden = false
      return
    }
  }
}
