import { Controller } from "@hotwired/stimulus"
import { v4 as uuidv4 } from 'uuid'

export default class extends Controller {
  static targets = ["newFields", "template", "keywordInput"]

  connect() {
    this.update()
  }

  modifyWithKeyboard(event) {
    if (event.key === 'Enter' || event.key === 'Tab' || event.key === 'ArrowDown' || event.key === ',' || event.key === ';') {
      event.preventDefault()
      if (this.blankInputs().length == 0) {
        this.addKeyword(event)
      }
      this.focusNextInput(event)
    } else if (event.key === 'ArrowUp') {
      event.preventDefault()
      this.focusPreviousInput(event)
    } else if (event.key === 'Backspace' && event.srcElement.value == '') {
      event.preventDefault()
      this.focusPreviousInput(event)
      this.removeKeyword(event)
    }
  }

  addKeyword(event) {
    var newElement = this.templateTarget.content.firstElementChild.cloneNode(true)
    newElement.innerHTML = newElement.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
    newElement.innerHTML = newElement.innerHTML.replace(/NEW_ID/g, uuidv4())

    this.newFieldsTarget.insertAdjacentElement('beforebegin', newElement)
  }

  removeKeyword(event) {
    event.preventDefault()

    if (this.visibleInputs().length == 1) {
      // Do not remove the last input
      return
    }

    let wrapper = event.target.closest("[data-keyword-wrapper]")

    // Existing records are hidden, flagged for deletion (if possible) and text inputs disabled
    wrapper.hidden = true
    const destroyInput = wrapper.querySelector("input[name*='_destroy']");
    if (destroyInput != null) {
      destroyInput.value = 1;
    }
    wrapper.querySelectorAll("input[type='text']").forEach(element => {
      element.disabled = true;
    });
  }

  // private

  update(event) {
    // Ensure there is always a blank input
    if (!this.hasBlankInputs()) {
      this.addKeyword(event)
    }

    // Update the indicator for all inputs
    this.keywordInputTargets.forEach((input) => {
      this.updateIndicator({ target: input })
    })
  }

  hasBlankInputs() {
    return this.blankInputs().length > 0
  }

  blankInputs() {
    return this.visibleInputs().filter((input) => input.value == "")
  }

  visibleInputs() {
    if (!this.hasKeywordInputTarget) { return [] }
    return this.keywordInputTargets.filter((input) => !input.closest("[data-keyword-wrapper]").hidden)
  }

  focusNextInput(event) {
    const inputs = this.visibleInputs();
    const currentInput = event.target
    const currentInputIndex = Array.from(inputs).indexOf(currentInput)
    const nextInput = inputs[currentInputIndex + 1]

    if (nextInput) {
      nextInput.selectionStart = nextInput.selectionEnd = nextInput.value.length;
      nextInput.focus()
    }
  }

  focusPreviousInput(event) {
    const inputs = this.visibleInputs();
    const currentInput = event.target
    const currentInputIndex = Array.from(inputs).indexOf(currentInput)
    const previousInput = inputs[currentInputIndex - 1]

    if (previousInput) {
      previousInput.selectionStart = previousInput.selectionEnd = previousInput.value.length;
      previousInput.focus()
    }
  }

  updateIndicator(event) {
    const input = event.target
    const indicator = input.closest("[data-keyword-wrapper]").querySelector("[data-keyword-indicator]")

    if (input.value == "") {
      indicator.innerHTML = ""
    } else {

      indicator.innerHTML = this.getKeywordType(input.value)
    }
  }

  getKeywordType(keyword) {
    // Order matters...A quoted boolean phrase is treated as an exact phrase
    if (keyword.startsWith('"') && keyword.endsWith('"')) {
      return "Exact"
    } else if (keyword.includes(" OR ") || keyword.includes(" AND ") || keyword.includes(" NOT ") || keyword.includes(" NEAR ") || keyword.includes(" NEAR/")) {
      return "Boolean"
    } else if (keyword.includes(" ")) {
      return "Phrase"
    } else if (keyword.length > 0) {
      return "Single Word"
    } else {
      return ""
    }
  }
}
