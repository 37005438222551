'use strict';
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ['toggle', 'section']

  connect() {
    if (this.wasCollapsed()) {
      this.toggleTarget.classList.add('collapsed');
      this.sectionTarget.classList.add('collapsible-nav-group--closed');
    }

    this.setAriaExpanded();
  }

  toggle(event) {
    event.preventDefault();

    const collapsed = this.isCollapsed();

    this.toggleTarget.classList.toggle('collapsed', !collapsed);
    this.sectionTarget.classList.toggle('collapsible-nav-group--closed', !collapsed);

    this.setAriaExpanded();
    this.storeState();
  }

  setAriaExpanded() {
    const ariaExpanded = this.isCollapsed() ? 'false' : 'true';

    this.toggleTarget.setAttribute('aria-expanded', ariaExpanded);
  }

  storeState() {
    this.initLocalStorage();
    const storedState = JSON.parse(localStorage.getItem('sidebarState'));
    const section = this.sectionTarget.id;

    const newState = {
      ...storedState,
      [section]: {
        collapsed: this.isCollapsed(),
      }
    }
    localStorage.setItem('sidebarState', JSON.stringify(newState));
  }

  isCollapsed() {
    return this.toggleTarget.classList.contains('collapsed');
  }

  wasCollapsed() {
    const storedState = localStorage.getItem('sidebarState')
    const section = this.sectionTarget.id;

    if (storedState) {
      const state = JSON.parse(storedState);
      return state[section] && state[section].collapsed;
    }
    return false;
  }

  initLocalStorage() {
    if (!localStorage.getItem('sidebarState')) {
      localStorage.setItem('sidebarState', JSON.stringify({}));
    }
  }
}
