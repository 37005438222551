import { Controller } from "@hotwired/stimulus";
import { parsedStorage, storageIsPresent } from "../utils/local_storage_utils"

export default class extends Controller {
  static targets = [
    'draftId',
    'campaigns',
    'organizations',
    'people',
    'subject',
    'body'
  ]

  connect() {
    this.setDraftId()
    this.populateForm()
  }

  setDraftId() {
    if (this.getDraftId() === '') { this.draftIdTarget.value = "draft-new-post" }
  }

  getDraftId() {
    return this.draftIdTarget.value
  }

  populateForm() {
    const draft = this.getDraft()

    if ([undefined, null, ''].includes(draft)) return

    if (draft.subject) {
      this.subjectTarget.value = draft.subject
    }
    if (draft.body) {
      this.bodyTarget.firstElementChild.innerHTML = draft.body
      this.bodyTarget.nextElementSibling.value = draft.body
    }
    if (draft.campaigns) {
      this.updateSelectOptions(draft.campaigns, this.campaignsTarget)
    }
    if (draft.organizations) {
      this.updateSelectOptions(draft.organizations, this.organizationsTarget)
    }
    if (draft.people) {
      this.updateSelectOptions(draft.people, this.peopleTarget)
    }
    if (draft.teams) {
      this.updateSelectOptions(draft.teams, this.teamsTarget)
    }
  }

  saveBody() {
    const html = this.bodyTarget.firstElementChild.innerHTML
    this.updateDraft({ body: html })
  }

  saveSubject(event) {
    this.updateDraft({ subject: event.target.value })
  }

  saveDraftSelection(event) {
    const attribute = event.target.dataset.postDraftTarget

    let selections = []

    Array.from(event.target.options).forEach((opt) => {
      if (opt.selected) {
        selections.push({
          'text': opt.text,
          'value': opt.value
        })
      }
    })

    this.updateDraft({ [attribute]: selections })
  }

  updateSelectOptions(optionsArray, target) {
    optionsArray.forEach((obj) => {
      var option = new Option(
        obj.text,
        obj.value,
        true,
        true
      );

      const existingOptionArr = Array.from(target.options)
      const optionExists = (existingOption) => { return existingOption.value === option.value }

      if (!existingOptionArr.some(optionExists)) { target.append(option) }
    })
  }

  updateDraft(data) {
    const allDrafts = this.getAllDrafts()
    const existingDraft = this.getDraft()
    const draftId = this.getDraftId()

    this.setDrafts({
      ...allDrafts,
      [draftId]: {
        ...existingDraft,
        ...data,
      }
    })
  }

  setDrafts(data) {
    localStorage.setItem('advoc8_post', JSON.stringify(data));
  }

  getDraft() {
    const storedDrafts = this.getAllDrafts()
    const draftId = this.getDraftId()

    return storedDrafts[draftId]
  }

  getAllDrafts() {
    return parsedStorage('advoc8_post') || {}
  }

}
