import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["label"];
  static values = { text: String };

  copy(event) {
    // Prevent the click from bubbling up to the parent element e.g closing a dropdown
    event.stopPropagation();
    event.preventDefault();
    this.clipboardText();
  }

  copied() {
    const label = this.hasLabelTarget ? this.labelTarget : this.element;
    const originalInnerHTML = label.innerHTML;

    // hide the original html, so that the any responsive layouts to jump around
    label.innerHTML = `
      <div class='position-relative'>
        <div class='position-absolute'>
          <i class='far fa-check mr-2'></i> Copied!
        </div>
        <div style='opacity: 0'>` + originalInnerHTML + `</div>
      </div>
    `
    label.blur();
    setTimeout(() => {
      label.innerHTML = originalInnerHTML;
    }, 2000);
  }

  // Clipboard API does not work with http://grm.test:3000/ - test with http://localhost:3000/
  clipboardText() {
    if (this.hasTextValue) {
      navigator.clipboard.writeText(this.textValue).then(() => this.copied());
      return;
    }

    if (this.element.nodeName === "FORM") {
      let formData = new FormData(this.element);

      fetch(this.element.action, {
        method: "POST",
        body: formData,
      })
        .then((response) => response.text())
        .then((data) => {
          navigator.clipboard.writeText(data).then(() => this.copied());
        });
      return;
    }
  }
}
