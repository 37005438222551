import Reveal from 'stimulus-reveal-controller'

export default class extends Reveal {

  show() {
    super.show()
    this.itemTargets.forEach(item => {
      item.querySelectorAll('input, select, textarea').forEach((el, _) => el.disabled = false);
      item.querySelector('input, select, textarea')?.focus()
    })
  }

  hide() {
    super.hide()
    this.itemTargets.forEach(item => {
      item.querySelectorAll('input, select, textarea').forEach((el, _) => el.disabled = true);
    })
  }

  toggle() {
    super.toggle()
    this.itemTargets.forEach(item => {
      if (item.classList.contains(this.hiddenClass)) {
        item.querySelectorAll('input, select, textarea').forEach((el, _) => el.disabled = true);
      } else {
        item.querySelectorAll('input, select, textarea').forEach((el, _) => el.disabled = false);
        item.querySelector('input, select, textarea')?.focus()
      }
    })
  }
}
