import { Controller } from "@hotwired/stimulus";
import debounce from "lodash/debounce";

export default class extends Controller {
  static targets = ["form", "resultBox", "resultItem", "resultItemList", "backdrop"];

  connect() {
    this.submit = debounce(this.submit.bind(this), 200, { leading: true });

    this.element.addEventListener("click", this.linkClicked.bind(this));
  }

  handleKeydown(event) {
    switch (event.key) {
      case "Escape":
        this.close();
        break;
      case "ArrowDown":
        event.preventDefault();
        this.setSelectedItem(this.selectedItemIndex + 1);
        break;
      case "ArrowUp":
        event.preventDefault();
        this.setSelectedItem(this.selectedItemIndex - 1);
        break;
      case "Enter":
        event.preventDefault();
        this.selectedItem().click();
        break;
    }
  }

  disconnect() {
    this.close();
  }

  selectHoveredItem(event) {
    this.setSelectedItem(this.resultItemTargets.indexOf(event.target));
  }

  submit() {
    this.formTarget.requestSubmit();
  }

  close() {
    this.element.remove();
  }

  resultItemListTargetConnected(element) {
    this.setSelectedItem(0);
  }

  selectedItem() {
    return this.resultItemTargets[this.selectedItemIndex];
  }

  setSelectedItem(index) {
    if (this.resultItemTargets[index] === undefined) return;

    this.selectedItemIndex = index;
    this.highlightItem(this.resultItemTargets[index]);
  }

  highlightItem(element) {
    this.resultItemTargets.forEach((item) => {
      item.classList.remove("active");
    });

    element.classList.add("active");
    element.scrollIntoView({ block: "nearest", behavior: "smooth" });
  }

  linkClicked(e) {
    if (e.target.dataset.turboFrame == "_top") {
      this.disconnect()
    }
  }
}
