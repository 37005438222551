import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    shouldFocusLastInput: Boolean
  }
  static targets = ["newFields", "template"]

  connect() {
    this.wrapperClass = this.data.get("wrapperClass") || "nested-fields"
    this.focusLastInput()
  }

  modifyWithKeyboard(event) {
    if (event.key === 'Enter') {
      this.addAssociation(event);
    } else if (event.key === 'Backspace' && event.srcElement.value == '' && this.visibleInputs().length > 1) {
      this.removeAssociation(event);
    }
  }

  addAssociation(event) {
    event.preventDefault()

    var newElement = this.templateTarget.content.firstElementChild.cloneNode(true)
    newElement.innerHTML = newElement.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())

    this.newFieldsTarget.insertAdjacentElement('beforebegin', newElement)

    this.focusLastInput()
  }

  removeAssociation(event) {
    event.preventDefault()

    let wrapper = event.target.closest(`.${this.wrapperClass}`)

    if (wrapper.dataset.newRecord == "true") {
      // New records are simply removed from the page
      wrapper.remove()

    } else {
      // Existing records are hidden and flagged for deletion
      wrapper.hidden = 'true'
      wrapper.querySelector("input[name*='_destroy']").value = 1
      wrapper.querySelectorAll("input[type='text']").forEach(element => { element.disabled = true })
    }

    this.focusLastInput()
  }

  visibleInputs() {
    return this.element.querySelectorAll("input[type=text]:not([data-controller=color-picker]):not(.hidden)")
  }

  focusLastInput() {
    if (!this.shouldFocusLastInputValue) { return }

    const inputs = this.visibleInputs();
    const lastInput = inputs[inputs.length - 1]
    if (lastInput) {
      lastInput.selectionStart = lastInput.selectionEnd = lastInput.value.length;
      lastInput.focus()
    }
  }
}
