import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  connect() {
    if (this.element.closest('.modal')) {
      setTimeout(() => {
        this.element.scrollIntoView({ behavior: "smooth", block: "start" });
      }, 500);
    } else {
      this.element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }
}
