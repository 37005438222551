import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["select"]

  selectTargetConnected(e) {
    this.updateQuery(e)
    this.confirmBtnState(e)
  }

  selectTargetChanged(e) {
    this.updateQuery(e.target)
    this.confirmBtnState(e.target)
  }

  updateQuery(target) {
    const link = target.closest('.card').querySelector('a')
    link.href = link.href + `&confirmed_mapping=${target.value}`
  }

  confirmBtnState(target) {
    const link = target.closest('.card').querySelector('a')
    target.value === '' ? link.classList.add('disabled') : link.classList.remove('disabled')
  }
}
