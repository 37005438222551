import { Controller } from "@hotwired/stimulus";
import debounce from "lodash/debounce"

// This used to extend stimulus-rails-autosave from the Stimulus Components library
// but it is pretty small and doesn't allow for the non-nested inputs that we need
export default class extends Controller {
  static targets = ['spinner']

  static values = {
    delay: Number
  }

  initialize() {
    this.save = this.save.bind(this)
  }

  connect() {
    if (this.delayValue > 0) {
      this.save = debounce(this.save, this.delayValue)
    }
  }

  save(event) {
    event.preventDefault();
    // Allow for autosave on inputs that are not nested inside a form by checking for the form attribute
    const form = event.target.hasAttribute('form') ? event.target.form : this.element

    this.spinner()

    form.requestSubmit()
  }

  spinner() {
    if (this.hasSpinnerTarget) {
      this.spinnerTarget.hidden = false
      addEventListener("turbo:submit-end", () => { this.spinnerTarget.hidden = true }) // hide spinner on turbo event
    }
  }
}
