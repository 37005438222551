import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["hideable", "enableable"]
  static values = { hideable: Array, enableable: Array }

  toggleTargetByValue(event) {
    this.hideableTargets.forEach(el => {
      el.hidden = this.hideableValue.includes(event.target.value)
    })
  }

  enableTargetByValue(event) {
    this.enableableTargets.forEach(el => {
      el.disabled = !this.enableableValue.includes(event.target.value)
    })
  }

  reloadParams(event) {
    const url = new URL(window.location.href);
    url.searchParams.set(event.target.name, event.target.value);
    window.history.replaceState({}, '', url.toString());
    location.reload()
  }

}
