import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["field", "cancelLink"]

  connect() {

  }

  fieldTargetConnected(element) {
    if (element.select) element.select();
  }

  submit() {
    if (this.processing) return; // prevent actions (like blur) firing a submit when already cancelling

    this.processing = true
    this.fieldTarget.form.requestSubmit();
  }

  cancel() {
    if (this.processing) return;

    this.processing = true
    this.cancelLinkTarget.click();
  }
}
