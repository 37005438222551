import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "issues", "teams", "postVisibility" ]

  connect() {
    this.update();
  }

  update() {
    const issueIds = [];
    Array.from(this.issuesTarget.selectedOptions).forEach((option) => {
      issueIds.push(option['value']);
    });

    const teamIds = [];
    Array.from(this.teamsTarget.selectedOptions).forEach((option) => {
      teamIds.push(option['value']);
    });

    const new_url = `/posts/visibility?issue_ids=${issueIds}&team_ids=${teamIds}`
    this.postVisibilityTarget.src = new_url
  }
}
