import PullToRefresh from 'pulltorefreshjs';

document.addEventListener("turbo:load", function() {
  if ($('.pull-to-refreshable')[0]) {
    PullToRefresh.init({
      mainElement: 'div.pull-to-refreshable',
      triggerElement: 'div.pull-to-refreshable',
      onRefresh() {
        Turbo.clearCache();
        Turbo.visit(window.location.toString());
      }
    });
  }
});

document.addEventListener("turbo:before-cache", function() {
  PullToRefresh.destroyAll();
})

