import { Controller } from "@hotwired/stimulus";
import { useClickOutside } from "stimulus-use";

export default class extends Controller {
  static targets = ["card", "chevron"];

  connect() {
    useClickOutside(this);
  }

  disconnect() {
    this.close();
  }

  clickOutside(e) {
    // When the select2 attribute data-close-on-select is set to false, clickOutside is triggered by selecting an option
    const isSelect2Element = Array.from(e.target.classList).some(cls => cls.includes('select2'));

    if (isSelect2Element) return;

    this.close();
  }

  toggle(e) {
    e.preventDefault();

    if (this.cardTarget.classList.contains("d-none")) {
      this.open();
    } else {
      this.close();
    }
  }

  open() {
    this.cardTarget.classList.remove("d-none");

    this.chevronTarget.classList.add("fa-rotate-180");
  }

  close() {
    this.cardTarget.classList.add("d-none");
    this.chevronTarget.classList.remove("fa-rotate-180");
  }
}
