import { Controller } from "@hotwired/stimulus"
import Tooltip from 'bootstrap/js/src/tooltip'
import { isTouchDevice } from '../utils';

export default class extends Controller {

  connect() {
    if (isTouchDevice()) return;

    $(this.element).tooltip()

    $(this.element).on('click', () => {
      $(this.element).tooltip('hide');
    });
  }

  disconnect() {
    if (isTouchDevice()) return;

    $(this.element).tooltip('dispose')
  }
}
