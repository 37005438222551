import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["shortcutPrompt"];

  connect() {
    document.addEventListener("keydown", this.handleShortcut.bind(this));

    this.setPlatformShortcutText();
  }

  disconnect() {
    document.removeEventListener("keydown", this.handleShortcut.bind(this));
  }

  handleShortcut(event) {
    if (typeof this.element.checkVisibility === 'function') {
      // If the element has a checkVisibility method, only allow the shortcut to be triggered if the element is visible
      // This is useful for elements that are hidden on mobile. The method is not available on older browsers like fairly recent Edge
      if (!this.element.checkVisibility()) {
          return;
      }
    }

    if (event.metaKey && event.key === "k" || event.ctrlKey && event.key === "k") {
      event.preventDefault();
      this.element.click();
    }
  }

  setPlatformShortcutText() {
    if (!this.hasShortcutPromptTarget) { return }

    if (navigator.platform.match(/(Mac|iPhone|iPod|iPad)/i)) {
      this.shortcutPromptTarget.innerText = "⌘ K";

    } else {
      this.shortcutPromptTarget.innerText = "Ctrl+K";
    }
  }
}
