import { Controller } from "@hotwired/stimulus";
import debounce from "lodash/debounce";

export default class extends Controller {
  static targets = [
    'alert',
    'checkbox',
    'divider',
    'currentLogoInput',
    'currentLogoWrapper',
    'removeCurrentLogo',
    'suggestedLogoInput',
    'suggestedLogoImage',
    'suggestedLogoWrapper',
    'website'
  ]

  initialize() {
    this.setClearbitUrl = debounce(this.setClearbitUrl, 500).bind(this)
  }

  handleClearbitError() {
    this.suggestedLogoImageTarget.addEventListener("error", () => {
      this.currentLogoWrapperTarget.hidden = false
      this.dividerTarget.hidden = true
      this.suggestedLogoWrapperTarget.hidden = true
      this.alertTarget.hidden = false
      this.resetCheckbox();
    })
  }

  handleClearbitLoad() {
    this.currentLogoWrapperTarget.hidden = false
    this.dividerTarget.hidden = false
    this.suggestedLogoWrapperTarget.hidden = false
    this.alertTarget.hidden = true
    this.resetCheckbox();
  }

  setClearbitUrl() {
    let url = `https://logo.clearbit.com/${this.websiteTarget.value.trim()}`;

    if (this.hasSuggestedLogoImageTarget) this.suggestedLogoImageTarget.src = url
    this.handleClearbitError()
  }

  toggleCheckbox() {
    if (!this.checkboxTarget.checked) {
      this.checkboxTarget.checked = true
      this.checkboxTarget.nextElementSibling.innerHTML = "<i class='far fa-check mr-1'></i> Using Logo";
      this.currentLogoWrapperTarget.hidden = true
      this.dividerTarget.hidden = true
      this.currentLogoInputTarget.value = '';
      this.suggestedLogoInputTarget.value = this.suggestedLogoImageTarget.src
      if (this.hasRemoveCurrentLogoTarget) this.removeCurrentLogoTarget.checked = false
    } else {
      this.resetCheckbox()
      this.currentLogoWrapperTarget.hidden = false
      this.dividerTarget.hidden = false
      this.suggestedLogoInputTarget.value = ''
    }
  }

  resetCheckbox() {
    this.checkboxTarget.checked = false
    this.checkboxTarget.nextElementSibling.innerHTML = "Use Logo";
  }
}
