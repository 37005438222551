import List from 'list.js';

const init = (el) => {
  let options = el.dataset.options;
  options = options ? JSON.parse(options) : {};

  const isListHasItems = $(el).find('.list li').length;

  const listPaginationPrev = el.querySelector('.list-pagination-prev');
  const listPaginationNext = el.querySelector('.list-pagination-next');

  if (!isListHasItems) {
    return false;
  }

  // !NOTE: The list doesn't work if we did not provide an id for the element
  const listObj = new List(el, options);

  // Pagination (next)
  if (listPaginationNext) {
    listPaginationNext.addEventListener('click', function (e) {
      e.preventDefault();

      var nextItem = listObj.i + listObj.page;

      if (nextItem <= listObj.size()) {
        listObj.show(nextItem, listObj.page);
      }
    });
  }

  // Pagination (prev)
  if (listPaginationPrev) {
    listPaginationPrev.addEventListener('click', function (e) {
      e.preventDefault();

      var prevItem = listObj.i - listObj.page;

      if (prevItem > 0) {
        listObj.show(prevItem, listObj.page);
      }
    });
  }
};

document.addEventListener("turbo:load", function() {
  const toggle = document.querySelectorAll('[data-toggle="lists"]');
  const toggleSort = document.querySelectorAll('[data-toggle="lists"] [data-sort]');

  if (typeof List !== 'undefined') {

    if (toggle) {
      [...toggle].forEach((el) => {
        init(el);
      });
    }

    if (toggleSort) {
      [...toggleSort].forEach((el) => {
        el.addEventListener('click', (e) => {
          e.preventDefault();
        });
      });
    }
  }

});

