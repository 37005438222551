import autosize from 'autosize';

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    autosize(this.element);
  }

  disconnect() {
    autosize.destroy(this.element);
  }
}
