import { Application } from "@hotwired/stimulus"

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

document.addEventListener('turbo:before-cache', function () {
  application.controllers.forEach(function (controller) {
    if (typeof controller.teardown === 'function') {
  controller.teardown();
    }
  });
});

export { application }
