import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  // TODO: this is a direct port of existing code, but it's ugly and should be refactored at next opportunity

  connect() {
    $(this.element).on('click', '.add_fields', function(event) {
      var regexp, time;
      time = new Date().getTime();
      regexp = new RegExp($(this).data('id'), 'g');
      $(this).before($(this).data('fields').replace(regexp, time));
      return event.preventDefault();
    });

    $(this.element).on('click', '.remove_fields', function(event) {
      $(this).closest('.attachment-fields').find('input[type=hidden].nested-destroy-field').val('1');
      $(this).closest('.attachment-fields').removeClass('d-flex').hide();
      $(this).closest('.attachment-fields').find("input[type=file]").remove()
      return event.preventDefault();
    });
  }

  disconnect() {
    $(this.element).off('click', '.add_fields')
    $(this.element).off('click', '.remove_fields')
  }
}
