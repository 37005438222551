import { Controller } from "@hotwired/stimulus";
export default class extends Controller {
  static targets = ["clickable"];

  click(event) {
    if (this.hasClickableTarget) {
      this.clickableTarget.click();
    }
  }
}
