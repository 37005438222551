// Quill Editor

import { Controller } from "@hotwired/stimulus"
import {
  createPostEditor,
  handlePostMentions,
  setEditorContent
} from "../post-editor.js";

export default class extends Controller {
  static targets = [
    'editor'
  ]

  static values = {
    bodyHtml: String
  }

  connect() {
    const postsEditor = createPostEditor(this.editorTarget);
    handlePostMentions(postsEditor);
    setEditorContent(this.bodyHtmlValue);
    if (this.editorTarget.autofocus) {
      postsEditor.focus();
    }
  }
}
