import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["item"]

  static values = {
    interval: {
      type: Number,
      default: 5000
    }
  }

  connect() {
    this.itemHeight = this.itemTargets[0].offsetHeight

    setTimeout(this.scrollToNextItem.bind(this, 0), this.intervalValue)
  }

  scrollToItem(index) {
    const offset = this.itemHeight * index
    this.element.scrollTo({ top: offset, behavior: 'smooth' })

    setTimeout(this.scrollToNextItem.bind(this, index), this.intervalValue)
  }

  scrollToNextItem(index) {
    if (index < this.itemTargets.length - 1) {
      this.scrollToItem(index+1)
    } else {
      this.scrollToItem(0)
    }
  }
}
