import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    setInterval(this.sendHeartbeat, (5 * 60 * 1000));
  }

  sendHeartbeat() {
    fetch('/session_heartbeat', { headers: { 'X-Requested-With': 'XMLHttpRequest' } });
  }
}
