import { Controller } from "@hotwired/stimulus"
import Flatpickr from 'stimulus-flatpickr';

// https://github.com/adrienpoly/stimulus-flatpickr

export default class extends Flatpickr {
  static values = {
    mode: { type: String, default: 'date' }
  }

  initialize() {
    //global options
    if (this.modeValue == 'date') {
      this.config = {
        altInput: true,
        altFormat: 'j M Y' // humanize date - make sure the source input value is not already humanized
      };
    } else if (this.modeValue == 'time') {
      this.config = {
        enableTime: true,
        altInput: true,
        altFormat: 'j M Y h:i K' // humanize time - make sure the source input value is not already humanized
      };
    }
  }

  connect() {
    super.connect();

    // Change the value when the hidden input changes. This is needed for the clear button to work in search-filters-controller.
    this.element.addEventListener('change', this.updateValue.bind(this));
  }

  updateValue() {
    this.fp.setDate(this.element.value, false);
  }
}
